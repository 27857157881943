import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://hnswallet/<br />3,500.00 HNS<br />0.02 ETH<br />$95.28<br /><br />🥈 https://backups/<br />102.00 HNS<br />0.00 ETH<br />$2.78<br /><br />🥉 https://xrp-wallet/<br />20.00 HNS<br />0.00 ETH<br />$0.54<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://travelwallet/<br />1,791.00 HNS<br />0.01 ETH<br />$48.75<br /><br />🥈 https://travelcoin/<br />1,330.00 HNS<br />0.01 ETH<br />$36.20<br /><br />🥉 https://xn--8l8hgd/ 👉 https://🏋🏾/<br />1,101.00 HNS<br />0.01 ETH<br />$29.97<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▎▁▁▁▁▁▁▁▁▁▁▁ ] 31.50%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▉▌▁▁▁▁▁▁▁▁ ] 50.2%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6541)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)