import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
//import ContactForm from '@widgets/ContactForm'
//import ContactInfo from '@widgets/ContactInfo'
//import Commitment from '@widgets/Commitment'
import Testimonial from '@widgets/Testimonial'

export default () => (
    <>
      <Seo title='Botmetrics' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle header='Botmetrics' subheader='Updated daily' />
          <Divider />
          {/*<ContactForm />*/}
          <Testimonial />
        </Main>
        {/*<Sidebar>
          <Commitment />
          <Divider />
          <ContactInfo />
        </Sidebar>*/}
      </Stack>
    </>
  )